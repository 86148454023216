<nav class="router-nav">
  <ul>
    <li><a routerLink="/" routerLinkActive="active">Home</a></li>
    <li><a routerLink="/celticshift" routerLinkActive="active">Celtic Shift</a></li>
    <li><a routerLink="/videotest" routerLinkActive="active">Video Test</a></li>
    <li><a routerLink="/videoupload" routerLinkActive="active">Video Upload</a></li>
  </ul>
</nav>
<div>
  <header-bar>
  </header-bar>
</div>

<div class="router-container">
  <router-outlet>
  </router-outlet>
</div>
