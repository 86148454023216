import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class MetaTagService {
  constructor(private title: Title, private meta: Meta) { }


  updateTitle(title: string) {
    this.title.setTitle(title);
  }

  updateOgUrl(url: string) {
    this.meta.updateTag({ name: 'og:url', content: url });
  }

  updateDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc });
  }

  updateVideo(video: string) {
    this.meta.updateTag({ name: 'og:video', content: video });
    this.meta.updateTag({ name: 'og:video:type', content: 'video/mp4' });
  }

  updateVideoSize(height: string, width: string) {
    this.meta.updateTag({ name: 'og:video:height', content: height });
    this.meta.updateTag({ name: 'og:video:width', content: width });
  }
}
