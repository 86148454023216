<div class="container">
  <h1>
    Uploady
  </h1>

  <div ng2FileDrop
      [uploader]="uploader">
    Drop File Here
  </div>
</div>
