<div class="header-container">
  <div class="floater">
    <h1>
      By the Woodside
    </h1>
    <p>
      By my left ball it sure is.
    </p>
  </div>
  <div class="reset"></div>
</div>
