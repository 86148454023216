import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CelticShiftJarService {
  private baseUrl: string = 'https://bythewoodside.com:3000/';

  constructor(private http: HttpClient) {
  }

  getFileList(): Observable<any> {
    return this.http.get(this.baseUrl, { responseType: 'json' });
  }

  formatLinkFromFilename(filename: string) {
    return `${this.baseUrl}download/${filename}`;
  }
}
