import { Component, OnInit } from '@angular/core';
import { CelticShiftJarService } from 'src/services/celticShiftJarService';
import { MetaTagService } from 'src/services/metaTagService';

@Component({
  selector: 'videotest',
  templateUrl: './videotest.component.html',
  styleUrls: ['./videotest.component.less']
})
export class VideoTestComponent implements OnInit {
  filenames: Array<string>;

  constructor(private metaTagService: MetaTagService) {

  }

  ngOnInit() {
    this.metaTagService.updateVideo('https://bythewoodside.com/testvideo.mp4');
    this.metaTagService.updateVideoSize('1080', '2224');
  }
}
