import { Component } from '@angular/core';
import { rejects } from 'assert';
import { FileUploader } from 'ng2-file-upload';

@Component({
  selector: 'video-upload',
  templateUrl: './video-upload.component.html',
  styleUrls: ['./video-upload.component.less']
})
export class VideoUploadComponent {
  filenames: Array<string>;
  uploader: FileUploader;

  constructor() {
    this.uploader = new FileUploader({
      url: 'fdfdsf'
    });
  }
}
