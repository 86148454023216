import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CelticShiftComponent } from './celticshift/celticshift.component';
import { HomepageComponent } from './homepage/homepage.component';
import { VideoUploadComponent } from './video-upload/video-upload.component';
import { VideoTestComponent } from './videotest/videotest.component';

const routes: Routes = [
  { path: 'celticshift', component: CelticShiftComponent },
  { path: 'videotest', component: VideoTestComponent },
  { path: 'videoupload', component: VideoUploadComponent },
  { path: '', component: HomepageComponent },
  { path: '**', redirectTo: '/' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
